import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/montserrat';
import { ThemeProvider } from '@mui/material/styles';
// import { SentryDSN, environment } from './secrets';
import theme from './theme';
import Routes from './Routes';
import { AppProvider } from './AppContext';
// import * as Sentry from '@sentry/react';

const rootElement = document.getElementById('root');
// const sentryLog = environment == 'development' ? true : false;

// Sentry.init({
//     dsn: SentryDSN,
//     debug: sentryLog,
//     environment: 'production',
//     integrations: [
//         new Sentry.BrowserTracing({
//             tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//         }),
//         new Sentry.Integrations.Breadcrumbs({
//             console: true,
//         }),
//         new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     // tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
        <AppProvider>
            <ThemeProvider theme={theme}>
                <Routes />
            </ThemeProvider>
        </AppProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
