import { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, Button, Box, Typography, Link, Breadcrumbs } from "@mui/material";
import CreateDataset from "./CreateDataset";
import FineTuneConfiguration from "./FinetuneConfiguration";
import InferenceConfiguration from "./InferenceConfiguration";
import { useNavigate } from "react-router-dom";
import {checkSubscriptionPlan} from "../utils/billing";

function CreateVersion({ projectIdFromUrl, projectName, version, step }) {
  const [activeStep, setActiveStep] = useState(Number(step));
  const [config, setConfig] = useState({});
  const [firebaseVersionId, setFirebaseVersionId] = useState(null);
  const [type, setType] = useState('completion');
  const [functionCalling, setFunctionCalling] = useState(false);
  const steps = ["Create Dataset", "Hyperparameter Tuning Configuration", "Inference Configuration"];

  const user = localStorage.getItem('userId');

  //get subscription plan from checkSubscriptionPlan function which will return a promise
  const [userPlan, setUserPlan] = useState('starter');
  useEffect(() => {
    checkSubscriptionPlan(user)
      .then((plan) => {
        setUserPlan(plan);
      });
  }, [user]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <CreateDataset onNext={handleNext} project={projectIdFromUrl} projectName={projectName}
          setFirebaseVersionId={setFirebaseVersionId} version={version} setType={setType} setFC={setFunctionCalling}/>;
      case 1:
        return <FineTuneConfiguration onNext={handleNext} onBack={handleBack} subscriptionPlan={userPlan}
        version={version} versionId={firebaseVersionId} setConfig={setConfig} projectId={projectIdFromUrl} projectName={projectName}
        setFirebaseVersionId= {setFirebaseVersionId} type={type} functionCalling={functionCalling}
        setType={setType} setFunctionCalling={setFunctionCalling}/>;
      case 2:
        return <InferenceConfiguration onNext={handleNext} onBack={handleBack} subscriptionPlan={userPlan}
          config={config} projectName={projectName} project={projectIdFromUrl} versionId={firebaseVersionId} 
          version={version} type={type} functionCalling={functionCalling}/>;
      default:
        return "Unknown step";
    }
  };

  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb" mb={3}>
        <Link underline="hover" href="/">
          Projects
        </Link>
        <Link underline="hover" onClick={handleGoBack}>
          {projectName}
        </Link>
        <Typography color="text.primary">Create Version</Typography>
      </Breadcrumbs>

      <Stepper activeStep={activeStep} >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box>
        {getStepContent(activeStep)}
        {(activeStep === 2) && (
            <Button onClick={handleBack}>Back</Button>
        )}
      </Box>
    </Box>
  );
}

export default CreateVersion;