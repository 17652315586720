import { getFirestore, getDoc, doc, setDoc } from 'firebase/firestore';
import { subscriptionStatusApiURL, starterProducts, proProducts, lemonCheckOutId } from "../secrets";
import app from '../firebase-app';

const db = getFirestore(app);

function getEmailById(userId) {
    return getDoc(doc(db, "app-users", userId))
      .then((userDoc) => {
        if (userDoc.exists() && userDoc.data().email) {
          const email = userDoc.data().email;
          // console.log("Email: ", email);
          // do something with the email
          return email;
        } else {
          // console.log("User does not exist or email not found");
          return null;
        }
      })
      .catch((error) => {
        // console.error("Error getting user document:", error);
        return null;
      });
  }

function createAppUser(id, email) {
  const appUsersRef = doc(db, "app-users", id);
  // Check if the user already exists
  getDoc(appUsersRef)
    .then((userSnapshot) => {
      // If the user already exists, skip the creation process
      if (userSnapshot.exists()) {
        // console.log("User already exists. Skipping creation.");
      }

      // Create a new app user
      setDoc(appUsersRef, { id, email }).then((result) => {});
    })
    .then(() => {
      // console.log("App user created successfully.");
    })
    .catch((error) => {
      // console.error("Error checking or creating app user: ", error);
      throw error;
    });
}

function checkSubscription(email) {
  //TODO: implement webhooks to handle subscription status changes and update firestore accordingly
  //Then we can just check the firestore for the user's subscription status

  // //check if the user has an active subscription in app user collection
  // const appUsersRef = doc(db, "app-users", userId);
  // getDoc(appUsersRef)
  //   .then((userSnapshot) => {
  //     // If the user already exists, skip the creation process
  //     if (userSnapshot.exists()) {
  //       if(userSnapshot.data().planDetails) {
  //         const planDetails = userSnapshot.data().planDetails;
  //         if(planDetails.current_period_end > Date.now()) {
  //           return planDetails;
  //         }
  //       }
  //     }
  //   })  
  //   .catch((error) => {
  //     // console.error("Error checking or creating app user: ", error);
  //     throw error;
  //   });

  const apiUrl = `${subscriptionStatusApiURL}?email=${email}`;
  return fetch(apiUrl, {
    mode: 'cors'
  })
    .then(response => response.json())
    .then(data => {
      // console.log(data);
      const customers = data.customers;
      const customerIds = Object.keys(customers);
      if (customerIds.length > 1) {
        return { status: "multipleCustomers", valid: false };
      }
      else if (customerIds.length === 0) {
        return { status: "noActivePlan", valid: false };
      }
      else {
        const customerId = customerIds[0];
        const customerStatus = customers[customerId]["subscriptions"];
        let planDetails = { status: 'noActivePlan', valid: false }
        customerStatus.forEach(subscription => {
          if (subscription["status"] === 'active' || subscription["status"] === 'on_trial') {
            const product = subscription["product"];
            let plan = '';
            //check if the product is in starterProducts or proProducts
            if(starterProducts.includes(product)) {
              plan = 'starter';
            }
            else if(proProducts.includes(product)) {
              plan = 'pro';
            }

            planDetails = { status: subscription["status"], valid: true, plan: plan, planEnd: subscription["current_period_end"]};
            //break out of the loop
            return;

            //insert plan details into firestore for the app user
            // const appUsersRef = doc(db, "app-users", userId);
            // setDoc(appUsersRef, { planDetails }, { merge: true }).then((result) => {});
          }
        });
        return planDetails;
      }
    })
    .catch(error => {
      // console.error(error);
    });
}


async function checkSubscriptionPlan(user) {
  return new Promise((resolve, reject) => {
    if (user) {
      // Show account screen if not logged in. redirect to subscription page if not subscribed
      getEmailById(user)
        .then((email) => {
          // console.log("Email returned", email);
          let userEmail = null;
          if (email) {
            userEmail = email;
          }
          else {
            userEmail = localStorage.getItem('userEmail');
            createAppUser(user, userEmail);
          }

          checkSubscription(userEmail)
            .then((subscriptionStatus) => {
              if (subscriptionStatus["valid"]) {
                // console.log("Subscription status: ", subscriptionStatus);
                resolve(subscriptionStatus["plan"]);
              }
              else {
                if (subscriptionStatus.status === 'noActivePlan') {
                  alert('You do not have active subscription plan. Please subscribe to access the app dashboard');
                  window.location.href = `https://easyllm.lemonsqueezy.com/checkout/buy/${lemonCheckOutId}`;
                  reject('No active subscription plan');
                }
                else {
                  alert('Multiple customer profiles in subscription. Please contact customer support');
                  window.location.href = 'https://www.easyllm.tech/';
                  reject('Multiple customer profiles in subscription');
                }
              }
            })
            .catch((error) => {
              // console.error(error);
              window.location.href = 'https://www.easyllm.tech/';
              reject(error);
            });

        })
        .catch((error) => {
          // console.error(error); // log any errors here
          window.location.href = 'https://www.easyllm.tech/';
          reject(error);
        });

    }
    else {
      window.location.href = '/account';
      reject('User not logged in');
    }
  });
}

export {getEmailById, createAppUser, checkSubscription, checkSubscriptionPlan};