/* Page for displaying user profile and options to update profile details*/

import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Typography, Box, Snackbar, Alert } from '@mui/material';
import { getFirestore, getDoc, doc, updateDoc} from 'firebase/firestore';
import { endService } from '../utils/serviceManagement';
import { AppContext } from "../AppContext";
import app from '../firebase-app';
import  ReadMore from './ReadMore';


const firestore = getFirestore(app);
const currentUserId = localStorage.getItem('userId')

const Settings = ({setSnackBar}) => {
  const [openAIKey, setOpenAIKey] = useState('');
  const [wandbKey, setWandbKey] = useState('');
  const [wandbCookie, setWandbCookie] = useState('');
  const [cohereKey, setCohereKey] = useState('');
  const [ai21Key, setAi21Key] = useState('');
  const [anyscaleKey, setAnyscaleKey] = useState('');
  const {loading, setLoading} = useContext(AppContext);

  const getUserSettings = async (uid) => {
    const settingsRef = doc(firestore, "app-users", uid);
    const settingsDoc = await getDoc(settingsRef);
    return settingsDoc.exists() && settingsDoc.data().settings ? settingsDoc.data().settings : {};
  };
  
  const updateSettings = async (uid, settings) => {
    setLoading(true)
    const settingsRef = doc(firestore, "app-users", uid);
    if(settings.openAIKey && settings.wandbKey){
      try {
        const currentSettings = await getUserSettings(uid);
        if(currentSettings.openAIKey){
          if (currentSettings.openAIKey !== settings.openAIKey || currentSettings.wandbKey !== settings.wandbKey) {
            // console.log("ending service");
            const endServiceResult =await endService(uid);
            // if (!endServiceResult) {
            //   console.error("Error ending service");
            // }
            // else{
            //   console.log("Service ended");
            // }
          }
        }
        await updateDoc(settingsRef, { settings: settings });
        setSnackBar({open: true, message: "Settings updated", severity: "success"});
        setLoading(false);
      } catch (error) {
          setSnackBar({open: true, message: "Not able to update settings", severity: "error"});
          setLoading(false);
      }
    }
    else{
      setSnackBar({open: true, message: "Provide both OpenAI API key and Weights and Biases API key", severity: "error"});
    }
  };
  

  useEffect(() => {
    const fetchSettings = async () => {
      const settings = await getUserSettings(currentUserId);
      if(settings){
      setOpenAIKey(settings.openAIKey || '');
      setWandbKey(settings.wandbKey || '');
      setWandbCookie(settings.wandbCookie || '');
      setCohereKey(settings.cohereKey || '');
      setAi21Key(settings.ai21Key || '');
      setAnyscaleKey(settings.anyscaleKey || '');
      }
    };

    fetchSettings();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await updateSettings(currentUserId, { openAIKey, wandbKey, wandbCookie, cohereKey, ai21Key, anyscaleKey});
  };

  return (
    <Box alignItems={'center'}>
      <Typography variant="h4">API Keys Settings</Typography>
        <TextField
          label="OpenAI API Key"
          value={openAIKey}
          onChange={(e) => setOpenAIKey(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="W&B (Weights and Biases) API Key"
          value={wandbKey}
          onChange={(e) => setWandbKey(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="W&B (Weights and Biases) Browser Cookie"
          value={wandbCookie}
          onChange={(e) => setWandbCookie(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Cohere API Key"
          value={cohereKey}
          onChange={(e) => setCohereKey(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="AI21 studio API Key"
          value={ai21Key}
          onChange={(e) => setAi21Key(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Anyscale API Key"
          value={anyscaleKey}
          onChange={(e) => setAnyscaleKey(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
    </Box>
  );
};

const Profile = () => {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'});

    const getUserProfile = async (uid) => {
        const profileRef = doc(firestore, "app-users", uid);
        const profileDoc = await getDoc(profileRef);
        return profileDoc.exists() ? profileDoc.data().profile : null;
    }

    const updateProfile = async (uid, profile) => {
        const profileRef = doc(firestore, "app-users", uid);
        try {
            await updateDoc(profileRef, { profile: profile });
            setSnackBar({open: true, message: "Profile updated", severity: "success"});
        } catch (error) {
            // console.error("Error updating or creating document: ", error);
            setSnackBar({open: true, message: "Not able to update profile", severity: "error"});
        }
    }

    useEffect(() => {
        const fetchProfile = async () => {
            const profile = await getUserProfile(currentUserId);
            if(profile){
            setName(profile.name || '');
            setCompany(profile.company || '');
            }
        };

        fetchProfile();
    }
    , []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await updateProfile(currentUserId, { name, company });
    }

    return (
        <>
            <Box display={'flex'} flexDirection={'row'} gap={3} flexWrap={'wrap'} alignItems    ={'center'}>
            <ReadMore link={"https://www.easyllm.tech/docs/settings.html/#settings"} urlName={"Setup API keys"} styleCode={'form-help-text form-help-profile'} />
                <Box display={'flex'} flexDirection={'row'} gap={.5} flexWrap={'wrap'}>
                    <Typography variant="h4">Profile</Typography>
                    <TextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </Box>
                <Settings setSnackBar={setSnackBar}/>
            </Box>
            <Snackbar open={snackBar.open} autoHideDuration={4000} onClose={() => setSnackBar({open: false})}>
                <Alert severity={snackBar.severity} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
      </>
    );
}

export default Profile;
