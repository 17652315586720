import React, { useState } from 'react';
import { Container} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import Navbar from './Navbar';

const auth = getAuth();

const AppLayout = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    localStorage.removeItem('userId');
    // sign out from firebase
    auth.signOut();
    window.location.href = '/';
    handleClose();
  };

  return (
    <>
      <Navbar
        handleMenuOpen={handleClick}
        anchorEl={anchorEl}
        handleMenuClose={handleClose}
        handleLogout={handleSignOut}
      />
      <Container fixed sx={{paddingTop: '20px', paddingBottom: '20px'}}>
        {children}
      </Container>

    </>
  );
}

export default AppLayout;