import { serviceUrl, apiUrl } from "../secrets";
import { getUserSettings } from "./userManagement";
import getTokens from '../utils/auth';

const url = apiUrl + '/' + localStorage.getItem('userId');

/**
 * Fetches the result from the /checkService endpoint using the GET method.
 * @param {string} userId - The user ID to be passed as a query parameter.
 * @returns {Promise<boolean>} - A promise that resolves to the Result value from the JSON response.
 * @throws {Error} - If the response is not ok or any other error occurs.
 */
async function checkNamespace(userId){
    try {
        const headers = await getTokens();
        const response = await fetch(`${serviceUrl}/checkService/${userId}`, {
            method: "GET",
            headers: {...{
                'Content-Type': 'application/json'
            }, ...headers},
        });

        if (!response.ok) {
            const errorMessage = "Failed to fetch data from /checkService";
            // You can update the status here if needed
            throw new Error(errorMessage);
        }

        const jsonResponse = await response.json();
        return jsonResponse.Result;
    }
    catch (error) {
        // console.error("Error in checkService:", error);
        throw error;
    }
}

/**
 * Calls the /lastRequestTime endpoint using the GET method to check the service.
 * @param {string} userId - The user ID to be passed as a query parameter.
 * @returns {Promise<boolean>} - A promise that resolves to boolean
 * @throws {Error} - If the response is not ok or any other error occurs.
 */
async function checkService(userId) {
    try {
        const headers = await getTokens();
        const response = await fetch(`${url}/lastRequestTime`, {
            method: "GET",
            headers: {...{
                'Content-Type': 'application/json'
            }, ...headers},
        });

        if (!response.ok) {
            const errorMessage = "Failed to fetch last request time";
            return false;
        }
        else{
            const jsonResponse = await response.json();
            if(jsonResponse.last_request_time){
                return true;
            }
            else{
                return false;
            }
        }

    } catch (error) {
        // console.error("Error in checkService:", error);
        return false;
    }
}

/**
* Calls the /createService endpoint using the POST method with the provided data.
* @param {string} userId - The user ID to be passed in the request body.
* @param {string} wandbApiKey - The wandb API key to be passed in the request body.
* @param {string} openaiApiKey - The openai API key to be passed in the request body.
* @returns {Promise<boolean>} - A promise that resolves to true if the Status is "Success", false otherwise.
* @throws {Error} - If the response is not ok or any other error occurs.
*/
async function createService(userId, wandbApiKey, openaiApiKey) {
    try {
        const requestBody = {
            user_id: userId,
            wandb_api_key: wandbApiKey,
            openai_api_key: openaiApiKey,
        };

        const headers = await getTokens();
        const response = await fetch(`${serviceUrl}/createService`, {
            method: "POST",
            headers: {...{
                'Content-Type': 'application/json'
            }, ...headers},
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            const errorMessage = "Failed to call /createService";
            throw new Error(errorMessage);
        }

        const jsonResponse = await response.json();
        if(jsonResponse.Result){
            for (let i = 0; i < 12; i++) {
                await new Promise(resolve => setTimeout(resolve, 10000));
                const serviceAvailable = await checkService(userId);
                if (serviceAvailable) {
                  return true;
                }
            }

            return false;
        }
        else{
            return false;
        }
    } catch (error) {
        // console.error("Error in createService:", error);
        throw error;
    }
}

/**
 * Calls the checkService function and performs the required actions based on the result.
 * @param {string} userId - The user ID to be passed to the checkService function.
 * @param {string} wandbApiKey - The wandb API key to be passed
 * @param {string} openaiApiKey - The openai API key to be passed
 * @returns {Promise<boolean>} - A promise that resolves to true if the process is successful, false otherwise.
 */
async function processService(userId, wandbApiKey, openaiApiKey) {
    try {
        const checkResult = await checkService(userId);

        if (checkResult) {
            return true;
        } 
        else {
            const endResult = await endService(userId);
            if (!endResult) {
                return false;
            }

            const createResult = await createService(userId, wandbApiKey, openaiApiKey);

            if (createResult) {
                return true;
            }

            return false;
        }
    } catch (error) {
        // console.error("Error in processService:", error);
        return false;
    }
}

/**
 * Calls the processService function and performs the required actions based on the result.
**/
async function verifyService(navigate) {
    const currentUserId = localStorage.getItem('userId');
    return getUserSettings(currentUserId)
    .then((settings) => {
        if (settings) {
            const wandbKey = settings.wandbKey || null;
            const OpenAIKey = settings.openAIKey || null;
            if (wandbKey && OpenAIKey) {
                return { canContinue: true, wandbKey, OpenAIKey };
            }
            else {
                alert("Please add required API Keys");
                navigate(`/userSettings`);
                return { canContinue: false, wandbKey: null, OpenAIKey: null };
            }
        }
        else {
            alert("Please add required API Keys");
            navigate(`/userSettings`);
            return { canContinue: false, wandbKey: null, OpenAIKey: null };
        }
    })
    .then(({ canContinue, wandbKey, OpenAIKey }) => {
        if (canContinue) {
            return processService(currentUserId, wandbKey, OpenAIKey)
                .then((result) => {
                    if (!result) {
                        alert("Problem in starting backend service. Please contact the customer support")
                    }
                    return result;
                })
                .catch((error) => {
                    // console.error("Error:", error);
                    alert("Problem in starting backend service. Please contact the customer support")
                    return false;
                });
        }
        else {
            return false;
        }

    })
    .catch((error) => {
        // console.error("Error:", error);
        alert("Problem in starting backend service. Please contact the customer support")
        return false;
    });
}

/*
 * Check whether the service is running. End the service if it is running (used when the user updates the API keys)
    * @param {string} userId - The user ID to be passed to the checkService function.
    * @returns {Promise<boolean>} - A promise that resolves to true if the process is successful, false otherwise.
 */
async function endService(userId) {
    try {
        // Check if service is running
        const checkResult = await checkNamespace(userId);

        if (checkResult) {
            // console.log("Service is running");
            // End the service
            const headers = await getTokens();
            const response = await fetch(`${serviceUrl}/endService/${userId}`, {
                method: "GET",
                headers: {...{
                    'Content-Type': 'application/json'
                }, ...headers},
            });

            if (!response.ok) {
                const errorMessage = "Failed to call /endService";
                throw new Error(errorMessage);
            }

            const jsonResponse = await response.json();
            return jsonResponse.Result;
        } else {
            // console.log("Service is not running");
            return true;
        }
    } catch (error) {
        // console.error("Error in endService:", error);
        throw error;
    }
}

export { verifyService, endService};