// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {firebaseConfig,reCAPTCHASecretKey, env} from './secrets';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";

const app = initializeApp(firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
if(env === "local"){
window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(reCAPTCHASecretKey),
  isTokenAutoRefreshEnabled: true
});
const analytics = getAnalytics(app);

export default app;

export {app, appCheck};