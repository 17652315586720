import { collection, getFirestore, query, where, doc, addDoc, deleteDoc, updateDoc, getDocs, getDoc } from "firebase/firestore";
import app from '../firebase-app';

const db = getFirestore(app);

// Function to check if a person is already a member of a team
async function isPersonInTeam(teamId, email) {
    try {
      const teamMembersRef = collection(doc(db, "teams", teamId), "members");
      
      // Query to check if the person is a member of the team
      const querySnapshot = await getDocs(query(teamMembersRef, where("email", "==", email)));
      
      return !querySnapshot.empty;
    } catch (error) {
      // console.error("Error checking if person is in team: ", error);
      throw error;
    }
  }
  
// Function to add a team member
async function addTeamMember(teamId, email) {
    try {
        const teamMembersRef = collection(doc(db, "teams", teamId), "members");

        // Check if the person is already a member of the team
        const personInTeam = await isPersonInTeam(teamId, email);
        if (personInTeam) {
            throw new Error("The person is already a member of the team.");
        }

        await addDoc(teamMembersRef, {
            email
        });
    } catch (error) {
        // console.error("Error adding team member: ", error);
        throw error;
    }
}

// Function to remove a team member
async function removeTeamMember(teamId, memberId) {
  try {
    const teamMembersRef = doc(db, "teams", teamId, "members", memberId);
    await deleteDoc(teamMembersRef);
  } catch (error) {
    // console.error("Error removing team member: ", error);
    throw error;
  }
}

// Function to create a team
async function createTeam(teamName) {
  try {
    const teamsRef = collection(db, "teams");
    await addDoc(teamsRef, {
      name: teamName,
      administrator: "", // Placeholder for the administrator's user ID
    });
  } catch (error) {
    // console.error("Error creating team: ", error);
    throw error;
  }
}

// Function to remove a team
async function removeTeam(teamId) {
  try {
    const teamRef = doc(db, "teams", teamId);
    await deleteDoc(teamRef);
  } catch (error) {
    // console.error("Error removing team: ", error);
    throw error;
  }
}

// Function to assign a team administrator
async function assignTeamAdministrator(teamId, administratorId) {
  try {
    const teamRef = doc(db, "teams", teamId);
    await updateDoc(teamRef, {
      administrator: administratorId,
    });
  } catch (error) {
    // console.error("Error assigning team administrator: ", error);
    throw error;
  }
}

// Function to change the team administrator
async function changeTeamAdministrator(teamId, newAdministratorId) {
  try {
    const teamRef = doc(db, "teams", teamId);
    await updateDoc(teamRef, {
      administrator: newAdministratorId,
    });
  } catch (error) {
    // console.error("Error changing team administrator: ", error);
    throw error;
  }
}

// Function to list team members
async function listTeamMembers(teamId) {
  try {
    const teamMembersRef = collection(doc(db, "teams", teamId), "members");
    const querySnapshot = await getDocs(teamMembersRef);
    const teamMembers = querySnapshot.docs.map((doc) => doc.data());
    return teamMembers;
  } catch (error) {
    // console.error("Error listing team members: ", error);
    throw error;
  }
}

// Function to list teams
async function listTeams() {
  try {
    const teamsRef = collection(db, "teams");
    const querySnapshot = await getDocs(teamsRef);
    const teams = querySnapshot.docs.map((doc) => doc.data());
    return teams;
  } catch (error) {
    // console.error("Error listing teams: ", error);
    throw error;
  }
}

const getUserSettings = async (uid) => {
  const settingsRef = doc(db, "app-users", uid);
  const settingsDoc = await getDoc(settingsRef);
  return settingsDoc.exists() ? settingsDoc.data().settings : {};
};

export {getUserSettings};