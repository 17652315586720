import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import firebaseConfig from '../secrets';
import HeroImageContainer from '../components/CoverSection/HeroImageContainer';
import Navbar from '../components/Navbar';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const uiConfig = {
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.GithubAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ],
    signInSuccessUrl: '/',
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            localStorage.setItem('userId', authResult.user.uid);
            localStorage.setItem('userEmail', authResult.user.email);
            return true;
        }
    }
};

const AuthContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px);
`;

const Account = () => {
  const [firebaseUI, setFirebaseUI] = useState(null);

  const initFirebaseUI = () => {
    if (firebaseUI === null) {
      const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
      setFirebaseUI(ui);
    }
  };

  useEffect(() => {
    initFirebaseUI();
    if (firebaseUI) {
      firebaseUI.start('#firebaseui-auth-container', uiConfig);
    }
  }, [firebaseUI]);

  return (
    <>
      <Navbar getStartedLink={true} />
      <AuthContainer>
      <HeroImageContainer zIndex="0" />
        <Typography variant="h4" component="h1" gutterBottom>
          Account
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Please sign in or create an account to access the app.
        </Typography>
        <Box mt={4} id="firebaseui-auth-container"></Box>
      </AuthContainer>
      </>
  );
};

export default Account;
