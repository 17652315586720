import { getToken  } from "firebase/app-check";
import {appCheck} from "../firebase-app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import app from '../firebase-app';

async function getTokens(){
    const auth = getAuth(app);
    return new Promise((resolve) => {
        onAuthStateChanged(auth, async (user) => {
            if(user) {
                try{
                    const appCheckToken = (await getToken(appCheck)).token;
                    const authToken = await user.getIdToken();
                    resolve({"Authorization": authToken, "AppCheckToken": appCheckToken});
                }catch(err){
                    resolve(null);
                }
            }else{
                resolve(null);
            }
        });
    });
}

export default getTokens;