import React from 'react';
import AppLayout from '../components/AppLayout';
import Profile from '../components/Profile';

const ProfilePage = () => {
  return (
    <AppLayout>
      <Profile />
    </AppLayout>
  );
};

export default ProfilePage;

