import AppLayout from '../components/AppLayout';
import LemonPricing from '../components/LemonPricing';

const AppPricing = () => {
  return (
    <AppLayout>
      <LemonPricing />
    </AppLayout>
  );
};

export default AppPricing;
