import { createTheme } from '@mui/material/styles';

// Create a custom theme with a primary color of violet and a secondary color of orange
const theme = createTheme({
  palette: {
    primary: {
      main: '#10a37f',
    },
    secondary: {
      main: '#ffffff',
    },
  },

  // Use the Roboto font for typography
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    overrides: {
      MuiTypography: {
        h1: {
          fontSize: '3rem', // Adjust based on your preference
          fontWeight: 600, // Adjust based on your preference
          lineHeight: 1.2, // Adjust based on your preference
          // Add any other desired styles for h1
          '@media (max-width: 600px)': {
            fontSize: '2.5rem',
          },
          '@media (max-width: 400px)': {
            fontSize: '2rem',
          },
        },
        h2: {
          fontSize: '2.5rem', // Adjust based on your preference
          fontWeight: 600, // Adjust based on your preference
          lineHeight: 1.2, // Adjust based on your preference
          // Add any other desired styles for h2
          '@media (max-width: 600px)': {
            fontSize: '2rem',
          },
          '@media (max-width: 400px)': {
            fontSize: '1.75rem',
          },
        },
      },
    }
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '9999px', // Set the border radius to create a pill shape
          '&.largeButton': {
            padding: '.5rem 3rem',
            fontSize: '1.25rem',
          },
        },
        containedWhite: {
          backgroundColor: 'white',
          color: (props) => props.theme.palette.primary.main,
          '&:hover': {
            backgroundColor: (props) => props.theme.palette.primary.main,
            color: 'white',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
  },

});

export default theme;
