import { useState } from "react";
import {
  TextField,
  IconButton,
  Chip,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Box
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";

function ConfigInput({
  config,
  setConfig,
  name,
  inputKey,
  inputType = "number",
  maxListLength = Infinity,
  allowedRange = { min: -Infinity, max: Infinity },
  helperText
}) {
  const [value, setValue] = useState("");
  const [minHelperText, setMinHelperText] = useState("");
  const [maxHelperText, setMaxHelperText] = useState("");
  const [chipHelperText, setChipHelperText] = useState(helperText);

  const handleChipDelete = (value) => {
    const newValues = config.values.filter((val) => val !== value);
    setConfig({ ...config, values: newValues });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleAddValue();
    }
  };

  const handleAddValue = () => {
    let newValue = inputType === "number" ? Number(value) : value;
    if (!allowedRange || (newValue >= allowedRange.min && newValue <= allowedRange.max)) {
      if (config.values.includes(newValue)) {
        setChipHelperText("Value added already");
        return;
      }
      const newValues = [...config.values, newValue].slice(-maxListLength);
      setConfig({ ...config, values: newValues });
      setValue("");
      setChipHelperText(helperText);
    }
    else{
      setChipHelperText(helperText);
    }
  };

  return (
    <Box style={{ display: "flex" }}>
      <Box style={{ flex: 1 }}>
        <Box style={{ display: "flex" }}>
          <Box style={{ flex: 1 }}>
            <Box display={"flex"} flexDirection={"row"} gap={2} alignItems={"center"}>
              <Typography variant="subtitle1"  >
                {name}
              </Typography>
              <RadioGroup
                row
                aria-label={inputKey + "-type"}
                name={inputKey + "-type"}
                value={config.type}
                onChange={(e) => {
                  const type = e.target.value;
                  if (type === "chip") {
                    setConfig({ type: "chip", values: [] });
                  } else {
                    setConfig({ type: "range", values: { min: allowedRange.min, max: allowedRange.max } });
                  }
                }}
              >
                <FormControlLabel value="chip" control={<Radio />} label="List" />
                <FormControlLabel value="range" control={<Radio />} label="Range" />
              </RadioGroup>
            </Box>
            {config.type === "chip" ? (
              <>
                <Box style={{ display: "flex", flexDirection: "column", gap: 5, flexWrap: "wrap" }}>
                  <TextField
                    size="small"
                    helperText={chipHelperText}
                    type={inputType}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    inputProps={{ min: 0 }}
                    sx={{ width: '100%' }}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleAddValue}>
                          <AddCircle />
                        </IconButton>
                      ),
                    }}
                  />
                  <Box style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                    {config.values.map((value, index) => (
                      <Chip
                        key={index}
                        label={value}
                        onDelete={() => handleChipDelete(value)}
                        color="primary"
                        size="small"
                        style={{ marginRight: "8px", marginBottom: "8px" }}
                      />
                    ))}
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                  size="small"
                    label="Min"
                    type={inputType}
                    helperText={minHelperText}
                    value={config.values.min}
                    onChange={(e) => {
                      const newValues = { ...config.values, min: e.target.value };
                      setConfig({ ...config, values: newValues });

                      let newValue = inputType === "number" ? Number(e.target.value) : e.target.value;
                      if (!allowedRange || (newValue >= allowedRange.min && newValue <= allowedRange.max)) {
                        setMinHelperText("");
                      }
                      else{
                        setMinHelperText(helperText)
                      }
                    }}
                    style={{ marginRight: "16px" }}
                  />
                  <TextField
                  size="small"
                    label="Max"
                    type={inputType}
                    helperText={maxHelperText}
                    value={config.values.max}
                    onChange={(e) => {
                      const newValues = { ...config.values, max: e.target.value };
                      setConfig({ ...config, values: newValues });

                      let newValue = inputType === "number" ? Number(e.target.value) : e.target.value;
                      if (!allowedRange || (newValue >= allowedRange.min && newValue <= allowedRange.max)) {
                        setMaxHelperText("");
                      }
                      else{
                        setMaxHelperText(helperText)
                      }
                    }
                    }
                    style={{ marginRight: "16px" }}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ConfigInput;


