import React, { createContext, useState } from 'react';
import { CircularProgress, Box } from '@mui/material';


export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    return (
        <AppContext.Provider value={{ loading, setLoading }}>
            <Box position="relative">
                {children}
                {loading && (
                    <Box
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        width="100%"
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bgcolor="rgba(0, 0, 0, 0.5)"
                        zIndex={9999}
                        position="fixed"
                    >
                        <CircularProgress color="success" style={{'color': '#10a37f'}} />
                    </Box>
                )}
            </Box>
        </AppContext.Provider>
    );
};
