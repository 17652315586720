import React from 'react';
import Slider from '@mui/material/Slider';

const DatasetPercentageSlider = ({
  value,
  onChange,
  min = 0,
  max = 100,
  step = 1,
}) => {
  const handleChange = (event, newValue) => {
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  return (
      <Slider
        value={value}
        onChange={handleChange}
        min={min}
        max={max}
        step={step}
        valueLabelDisplay="auto"
        aria-labelledby="percentage-slider"
      />
  );
};

export default DatasetPercentageSlider;
