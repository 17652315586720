import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content-center;
    top: revert;
    height: revert;
    
  }
`;

const ForegroundLeftImage = styled.img`
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  @media (max-width: 768px) {
    // display: none;
    position: relative;
    margin: auto;
    height: 200px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45%;
  }
  @media (min-width: 1366px) {
    height: 50%;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  z-index: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.1;
  transition: transform 0.3s ease-out;
`;

const ForegroundRightImage = styled.img`
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45%;
  }
  @media (min-width: 1366px) {
    height: 50%;
  }
`;

const HeroImageContainer = () => {
  const backgroundRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const background = backgroundRef.current;
      const { clientX, clientY } = event;

      const offsetX = -((clientX / window.innerWidth - 0.5) * 30);
      const offsetY = -((clientY / window.innerHeight - 0.5) * 30);

      background.style.transform = `translate(${offsetX}px, ${offsetY}px)`;
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <Container>
      <ForegroundLeftImage src="/assets/images/heroLeftImage.svg" alt="" />
      <BackgroundImage
        ref={backgroundRef}
        src="/assets/images/heroBackgroundImage.svg"
        className="clouds"
        alt=""
      />
      <ForegroundRightImage src="/assets/c40c84ca18d84633a9d86b4046a91437.svg" alt="" />
    </Container>
  );
};

export default HeroImageContainer;
