import React from 'react';
import AppLayout from '../components/AppLayout';
import CreateVersion from '../components/CreateVersion';

const CreateFinetune = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const projectIdFromUrl = urlParams.get('projectId');
  const projectName = urlParams.get('name');
  const version = Number(urlParams.get('version'));
  const step = urlParams.get('step');

  return (
    <AppLayout>
      <CreateVersion projectName={projectName} projectIdFromUrl={projectIdFromUrl} version={version} step={step}/>
    </AppLayout>
  );
};

export default CreateFinetune;

