import React from 'react';
import AppLayout from '../components/AppLayout';
import Versions from '../components/VersionManagement';

const Project = () => {

  return (
    <AppLayout>
      <Versions />
    </AppLayout>
  );
};

export default Project;

